/* eslint-disable @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps */

import React, { useEffect, useRef } from 'react';
import styles from './Videos.module.scss';
import { VideoItem } from './VideoItem/VideoItem';
import { fetchPostsById } from 'src/redux/slices/feedSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';

export const Videos = ({ circleId }: { circleId: string }) => {
  const { posts, currentPage, totalPages, isFetchingMore } = useAppSelector(
    (state: any) => state.feed
  );
  const dispatch = useAppDispatch();
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const loadMore = async () => {
    if (currentPage < totalPages && !isFetchingMore) {
      dispatch(fetchPostsById({ id: circleId, page: currentPage + 1 }));
    }
  };

  useEffect(() => {
    if (circleId) {
      dispatch(fetchPostsById({ id: circleId, page: 1 }));
    }
  }, [circleId, dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          loadMore();
        }
      },
      {
        root: null,
        rootMargin: '200px',
        threshold: 1.0,
      }
    );

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => {
      if (triggerRef.current) observer.unobserve(triggerRef.current);
    };
  }, [triggerRef.current, currentPage, totalPages, isFetchingMore]);

  return (
    <div>
      <div className={styles.scrollableContainer}>
        {posts && posts.length > 0 ? (
          posts.map((item: any) => <VideoItem item={item} key={item._id} />)
        ) : (
          <div>No videos available for this circle</div>
        )}
        <div ref={triggerRef} className={styles.scrollTrigger} />
      </div>
      {isFetchingMore && (
        <div className={styles.loaderContainer}>
          <MaterialCircularPogress />
        </div>
      )}
    </div>
  );
};
