import { useEffect, useState } from 'react';
import { usersApi } from 'src/api/user';
import { TribeDetail } from 'src/pages/Home/const';
import { Circleitem } from './CircleItem/CircleItem';
import { CircularProgress } from '@mui/material';
import styles from './Circles.module.scss';
import { useNavigate } from 'react-router-dom';

export const Circles = () => {
  const [circles, setCircles] = useState<TribeDetail[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCircle, setSelectedCircle] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    usersApi
      .userMe()
      .then((res: any) => {
        if (!res.data.result.full_name) {
          // navigate(PathRoutes.CreateProfileRoute);
        } else {
          setCircles(res.data.tribe_details);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch circles:', error);
        setLoading(false);
      });
  }, [navigate]);

  const approvedCircles = circles
    ? circles
        .filter((item) => item.status === 'APPROVED')
        .sort((a, b) => {
          const roleOrder = { LEADER: 1, 'CO-LEADER': 2, MEMBER: 3 } as const;
          const aRole = a.role as keyof typeof roleOrder;
          const bRole = b.role as keyof typeof roleOrder;

          return (roleOrder[aRole] || 4) - (roleOrder[bRole] || 4);
        })
    : [];

  return (
    <div className={styles.container}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <CircularProgress />
        </div>
      ) : approvedCircles.length > 0 ? (
        <>
          <div className={styles.title}>Circles</div>
          <div
            className={`${styles.list} ${approvedCircles.length > 5 ? styles.scrollable : ''}`}
          >
            {approvedCircles.map((item) => (
              <Circleitem
                item={item}
                key={item._id}
                selectedCircle={selectedCircle}
                setSelectedCircle={setSelectedCircle}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.warning}>
          Your Circles will show up here when you are invited to a Circle or
          start one of your own.
        </div>
      )}
    </div>
  );
};
