import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthUser, getCurrentUser } from 'aws-amplify/auth';
import { RootState } from '../store/store';
import { usersApi } from 'src/api/user';

interface UserState {
  user: AuthUser | null;
  userId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  userId: null,
  loading: true,
  error: null,
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const currentUser = await getCurrentUser();
  return currentUser;
});

export const getUserId = createAsyncThunk('user/getUserId', async () => {
  const response = await usersApi.userMe();
  const userId = response.data.result._id;
  const userDetails = await usersApi.getUserById(userId);
  return { user: userDetails.data, userId };
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthUser | null>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.userId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch user';
      })
      .addCase(getUserId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.userId = action.payload.userId;
        state.loading = false;
      })
      .addCase(getUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch user ID';
      });
  },
});
export const { setUser, clearUser } = userSlice.actions;

export const currentUser = (state: RootState) => state.user.user;
export const getCurrentUserId = (state: RootState) => state.user.userId;

export default userSlice.reducer;
