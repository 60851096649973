import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  selectAllPosts,
  postsLoading,
} from 'src/redux/slices/posts/postsSlice';
import { circleApi } from 'src/api/circles';
import { TribeDetail } from '../Home/const';
import { UserInfo } from './UserInfo/UserInfo';
import TabsAuction from './Tabs/Tabs';
import styles from './UserCircle.module.scss';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';

export const UserCircle = () => {
  const [circleData, setCircleData] = useState<TribeDetail | null>(null);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const postData = useAppSelector(selectAllPosts);
  const isLoading = useAppSelector(postsLoading);
  console.log('hello');
  useEffect(() => {
    setCircleData(null);

    if (id) {
      circleApi.getCircle(id).then((res) => {
        setCircleData(res.data.result);
      });

      // dispatch(fetchAllPosts(id));
    }
  }, [id, dispatch]);

  return (
    <div className={styles.container}>
      {circleData && !isLoading ? (
        <>
          <UserInfo {...circleData} id={id} postLenght={postData.length} />
          <TabsAuction
            postData={postData}
            description={circleData?.description}
            circleId={id || ''}
          />
        </>
      ) : (
        <div>
          <MaterialCircularPogress />
        </div>
      )}
    </div>
  );
};
