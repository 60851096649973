import React from 'react';
import { Box, Typography } from '@mui/material';
import MaterialTextField from 'src/components/material/textField';
import styles from '../FirstStep/FirstStep.module.scss';

export interface SecondStepProps {
  error: string;
  code: string;
  setCode: (value: string) => void;
  newPassword: string;
  setNewPassword: (value: string) => void;
  handleConfirmResetPassword: () => void;
}

export const SecondStep: React.FC<SecondStepProps> = ({
  error,
  code,
  setCode,
  newPassword,
  setNewPassword,
  handleConfirmResetPassword,
}) => {
  return (
    <Box>
      {error && (
        <Typography variant="body2" color="error" align="center">
          {error}
        </Typography>
      )}
      <MaterialTextField
        label="Verification Code"
        name="code"
        id="code"
        variant="outlined"
        error={!!error}
        onChange={(e: any) => setCode(e.target.value)}
        value={code}
        color="primary"
      />
      <MaterialTextField
        label="New Password"
        name="newPassword"
        id="newPassword"
        variant="outlined"
        isPassword
        error={!!error}
        onChange={(e: any) => setNewPassword(e.target.value)}
        value={newPassword}
        color="primary"
      />
      <button className={styles.btn} onClick={handleConfirmResetPassword}>
        Reset Password
      </button>
    </Box>
  );
};
