import React, { FC, useRef, useState } from 'react';
import classNames from 'classnames';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import styles from './UploadImage.module.scss';
import { usersApi } from 'src/api/user';

interface UploadImageProps {
  defaultImage: string;
  onUpdate: (fileId: string | File) => void;
  classNameImageContainer?: string;
  children?: React.ReactNode;
  helperText?: string;
}

// interface UploadFileParams {
//   contentType: string;
//   name: string;
//   type: string;
// }

export const UploadImage: FC<UploadImageProps> = ({
  defaultImage,
  onUpdate,
  classNameImageContainer,
  // children,
  helperText,
}) => {
  const [previewImg, setPreviewImg] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // Update the preview image when defaultImage prop changes
  // useEffect(() => {
  //   console.log('defaultImage updated:', defaultImage); // Debugging log
  //   setPreviewImg(defaultImage);
  // }, [defaultImage]);

  const handleImageClick = () => {
    fileRef.current?.click();
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];

    if (uploadedFile) {
      if (uploadedFile.size <= 4194304) {
        setIsUploading(true);
        try {
          // Step 1: Request a pre-signed URL from the backend
          const response = await usersApi.uploadFile({
            contentType: uploadedFile.type, // Ensure this is the expected field
            name: 'avatarurl',
            type: 'image-avatar',
          });
          const { uploadURL, Key } = response.data;

          // Step 2: Upload the image to S3 using the pre-signed URL
          const uploadResponse = await fetch(uploadURL, {
            method: 'PUT',
            headers: {
              'Content-Type': uploadedFile.type,
            },
            body: uploadedFile,
          });

          // const getVideoId = await circleApi.getVideoId(Key);
          // console.log(getVideoId.data, 'getVideoId');

          if (!uploadResponse.ok) {
            throw new Error(
              `Upload failed with status: ${uploadResponse.status}`
            );
          }

          // Step 3: Update the preview and notify parent component
          const previewUrl = URL.createObjectURL(uploadedFile);
          setPreviewImg(previewUrl);
          // onUpdate(`https://d32w67rxkh5e8t.cloudfront.net/${Key}`);
          //stage
          onUpdate(`https://d30r677afi5rfh.cloudfront.net/${Key}`);
        } catch (error) {
          console.error('Error uploading file:', error);
          alert('Error uploading file. Check the console for more details.');
        } finally {
          setIsUploading(false);
        }
      } else {
        alert('File size should not exceed 4MB');
      }
    }
  };

  const handleIconClick = () => {
    navigate(`/how-it-works#info-photo`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Photo</div>
        <div onClick={handleIconClick}>
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.description}>
        Dunbar is all about authenticity, so most Members use a real photo of
        themselves
      </div>
      <div
        className={classNames(styles.photoContainer, classNameImageContainer)}
        style={{
          backgroundImage: previewImg
            ? `url(${previewImg})`
            : `url(${defaultImage})`,
          backgroundSize: 'cover', // Ensures the image covers the container
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Avoid repeating the image
          border: previewImg ? 'none' : '1px solid #ccc', // Provide a border if no image
        }}
        onClick={handleImageClick}
      >
        {!previewImg && !isUploading && !defaultImage && (
          <div className={styles.photoWrapper}>
            <PhotoCameraOutlinedIcon style={{ color: 'black' }} />
            Choose photo
          </div>
        )}
        {isUploading && <div className={styles.photoWrapper}>Uploading...</div>}
      </div>
      {!previewImg && (
        <div className="upload-image__description">{helperText}</div>
      )}

      <input
        accept=".jpg, .jpeg, .png"
        type="file"
        className={styles.hiddenInput}
        ref={fileRef}
        onChange={handleInputChange}
      />
    </div>
  );
};
