import Avatar from '@mui/material/Avatar';
import styles from './UserInfo.module.scss';
// import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { FC, useState } from 'react';
import { TribeDetail } from 'src/pages/Home/const';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { AddMembersModal } from '../AddMembersModal/AddMembersModal';
// import Popover from '@mui/material/Popover';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from 'src/redux/slices/loggedUserSlice';
import { CATEGORIES } from 'src/const/Categories/Categories';
import { CategoryItem } from 'src/components/common/Category/CategoryItem/CategoryItem';
import CreateIcon from '@mui/icons-material/Create';
import useMediaQuery from '@mui/material/useMediaQuery';
// import defaultUser from 'src/assets/images/defaults/default-user.webp';
import { CreatePost } from 'src/components/common/Modals/CreatePost/CreatePost';

export const UserInfo: FC<TribeDetail> = ({
  leader_name,
  leader_avatar_url,
  tribe_name,
  leader_id,
  _id,
  id,
  tribe_avatar_url,
  member_count_max = 0,
  members,
  habits = [],
  postLenght,
}) => {
  const [isOpenCreatePostModal, setIsOpenCreatePostModal] = useState(false);
  const [isOpenAddMember, setIsOpenAddMember] = useState(false);
  const user = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();

  const matches = useMediaQuery('(max-width:745px)');

  const isLeader = user?._id === leader_id;

  const uniqueCategories = Array.from(
    new Set(habits.map((habit: any) => habit.category))
  );

  const getCategoryImage = (category: string) => {
    const categoryData = CATEGORIES.find(
      (cat: any) => cat.name === category.toLowerCase()
    );
    return categoryData
      ? categoryData.img
      : CATEGORIES.find((cat: any) => cat.name === 'other')?.img;
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: matches
          ? `url(${tribe_avatar_url || leader_avatar_url})`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.avatar}>
        <Avatar
          src={tribe_avatar_url || leader_avatar_url}
          sx={{ width: 300, height: 300, borderRadius: '5px' }}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.dataTop}>
          <div className={styles.title}>{tribe_name}</div>
          {/* <div className={styles.bio}>{leader_name}</div> */}
        </div>
        <div className={styles.data}>
          <div className={styles.container_item}>
            <div className={styles.info}>
              {members.length} <span>Members</span>
            </div>
            <div className={styles.info}>
              {postLenght} <span>Posts</span>
            </div>
          </div>
          <div className={styles.categories}>
            <div className={styles.categoryList}>
              {uniqueCategories.map((category, index) => (
                <CategoryItem
                  key={index}
                  item={{ name: category, img: getCategoryImage(category) }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          {isLeader && (
            <div>
              <button
                onClick={() => setIsOpenAddMember(true)}
                className={styles.btn}
              >
                Members
              </button>
            </div>
          )}
          {isLeader && (
            <div>
              <button
                onClick={() => setIsOpenCreatePostModal(true)}
                className={styles.btn}
              >
                Challenge +
              </button>
            </div>
          )}
          <CreatePost
            isOpenModal={isOpenCreatePostModal}
            setIsOpenModal={setIsOpenCreatePostModal}
            tribeId={_id}
            leaderId={leader_id}
            leaderName={leader_name}
          />
          {isLeader && (
            <div
              onClick={() => navigate(`/edit-circle/${id}`)}
              className={styles.editIcon}
            >
              <CreateIcon sx={{ color: 'red', cursor: 'pointer' }} />
            </div>
          )}
        </div>
        <AddMembersModal
          isOpenModal={isOpenAddMember}
          setIsOpenModal={setIsOpenAddMember}
          circleId={_id}
          tribeName={tribe_name}
          isLeader={isLeader}
        />
      </div>
    </div>
  );
};
