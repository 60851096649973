import { useState } from 'react';
import { MuiModal } from 'src/components/material/MuiModal';
import styles from './CreatePost.module.scss';
import { UploadVideo } from './UploadVideo/UploadVideo';
import ChallengesInput from './Challenges/ChallengesInput';

import { postsApi } from 'src/api/postst';

interface CreatePostProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  tribeId: string;
  leaderId: string;
  leaderName: string;
}

export const CreatePost: React.FC<CreatePostProps> = ({
  isOpenModal,
  setIsOpenModal,
  tribeId,
  leaderId,
  leaderName,
}) => {
  const [videoId, setVideoId] = useState('');
  const [challenges, setChallenges] = useState<
    { name: string; category: string }[]
  >([]);
  const [videoError, setVideoError] = useState('');
  const [challengesError, setChallengesError] = useState('');

  const handleAddChallenge = (challenge: string) => {
    if (challenges.length >= 5) return; // Prevent adding more than 5 challenges
    const newChallenge = { name: challenge, category: 'Main' };
    setChallenges([...challenges, newChallenge]);
    setChallengesError(''); // Clear challenges error on valid input
  };

  const handleEditChallenge = (
    index: number,
    updatedChallenge: { name: string; category: string }
  ) => {
    const updatedChallenges = [...challenges];
    updatedChallenges[index] = updatedChallenge;
    setChallenges(updatedChallenges);
  };

  const handleDeleteChallenge = (index: number) => {
    const updatedChallenges = challenges.filter((_, i) => i !== index);
    setChallenges(updatedChallenges);
    if (updatedChallenges.length === 0) {
      setChallengesError('You should add at least one challenge');
    }
  };

  const validate = () => {
    let isValid = true;
    if (!videoId) {
      setVideoError('Video is required');
      isValid = false;
    } else {
      setVideoError(''); // Clear video error if valid
    }

    if (challenges.length === 0) {
      setChallengesError('You should add at least one challenge');
      isValid = false;
    } else {
      setChallengesError(''); // Clear challenges error if valid
    }

    return isValid;
  };

  const postChallenge = async () => {
    if (!validate()) return;

    const data = {
      tribe_id: tribeId,
      leader_id: leaderId,
      leader_name: leaderName,
      challenges,
      video_id: videoId,
    };

    try {
      await postsApi.postChallenge(data);
      window.location.reload();
    } catch (error) {
      console.error('Failed to create or fetch posts:', error);
    }
  };

  const handleVideoUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setVideoId(fileId);
      setVideoError(''); // Clear video error when a video is uploaded
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div className={styles.modalContent}>
        <div className={styles.title}>Create Challenge</div>
        <UploadVideo
          defaultVideo={''}
          onUpdate={handleVideoUpdate}
          helperText="Upload a video (max 60 seconds)"
        />
        {videoError && <div className={styles.error}>{videoError}</div>}

        <ChallengesInput
          onAddChallenge={handleAddChallenge}
          onEditChallenge={(index, name) =>
            handleEditChallenge(index, { name, category: 'Main' })
          }
          onDeleteChallenge={handleDeleteChallenge}
          challenges={challenges.map((ch) => ch.name)}
        />
        {challengesError && (
          <div className={styles.error}>{challengesError}</div>
        )}

        <button className={styles.btn} onClick={postChallenge}>
          Post Challenge
        </button>
      </div>
    </MuiModal>
  );
};
