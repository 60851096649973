import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from '../FirstStep/FirstStep.module.scss';

interface SignUpTypeProps {
  setType: (step: number) => void;
}

export const ThirdStep: FC<SignUpTypeProps> = ({ setType }) => {
  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ mt: 2, mb: 2, color: '#191920', fontWeight: 'bold' }}
      >
        Password Reset Successfully
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{ mt: 2, mb: 4, color: '#191920' }}
      >
        You can now log in with your new password.
      </Typography>

      <button className={styles.btn} onClick={() => setType(1)}>
        Go to Login
      </button>
    </Box>
  );
};
