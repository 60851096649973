import { PathRoutes } from 'src/const/Routes/PathRoutes';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BuildIcon from '@mui/icons-material/Build';

export const APKUrl =
  'https://play.google.com/store/apps/details?id=com.dunbarapp.Dunbar&hl=en&gl=US';
export const IOSUrl = 'https://apps.apple.com/us/app/dunbar-app/id6469200374';

export const ROUTES = [
  {
    title: 'Feedss',
    path: PathRoutes.FeedPageRoute,
    icon: <HomeOutlinedIcon />,
  },
  {
    title: 'Profile',
    path: PathRoutes.HomeRoute,
    icon: <PersonOutlineIcon />,
  },
  {
    title: 'Info',
    path: PathRoutes.HowItWorks,
    icon: <InfoOutlinedIcon />,
  },
  {
    title: 'Admin Panel',
    path: PathRoutes.AdminPageRoute,
    icon: <BuildIcon />,
  },
];

export const ROUTES_NO_AUTH = [
  {
    title: 'Info',
    path: PathRoutes.HowItWorks,
    icon: <InfoOutlinedIcon />,
  },
];
