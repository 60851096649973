const themeColors = {
  primary: { main: '#F0544F' },
  secondary: { main: '#EBEBF0', text: '#191920' },
  info: { main: '#101828' },
  text: { primary: '#5b5b66', dark70: '#5b5b66', dark100: '#191920' },
  error: { main: '#ED5349' },
  border: { main: '#667085', focus: '#5925DC' },
};

// Define the font object
const themeTypography = {
  // fontFamily: 'General Sans, sans-serif',
  fontFamily: 'TikTokFont, Arial, Tahoma, PingFangSC, sans-serif',
};

// Export the color and font objects
export { themeColors, themeTypography };
