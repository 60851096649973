import { Box, Typography } from '@mui/material';
import MaterialTextField from 'src/components/material/textField';
import styles from './FirstStep.module.scss';

export interface FirstStepProps {
  error: string;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  handleSendCode: () => void;
}

export const FirstStep: React.FC<FirstStepProps> = ({
  error,
  phoneNumber,
  setPhoneNumber,
  handleSendCode,
}) => {
  return (
    <Box>
      {error && (
        <Typography variant="body2" color="error" align="center">
          {error}
        </Typography>
      )}
      <MaterialTextField
        isPhoneNumber
        label="Phone Number"
        name="phoneNumber"
        id="phone-number"
        variant="outlined"
        error={!!error}
        onChange={(value: any) => setPhoneNumber(value)}
        value={phoneNumber}
        color="primary"
      />
      <button className={styles.btn} onClick={handleSendCode}>
        Send Code
      </button>
      {/* <Link to={PathRoutes.LoginRoute} className={styles.link}>
        <Button
          className={styles['btn']}
          sx={{
            background: themeColors.secondary.main,
            color: themeColors.secondary.text,
          }}
          fullWidth
          variant="text"
          size="large"
        >
          <WestIcon /> Back
        </Button>
      </Link> */}
    </Box>
  );
};
