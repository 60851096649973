import { FC } from 'react';
import { TribeDetail } from 'src/pages/Home/const';
import Avatar from '@mui/material/Avatar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import cn from 'classnames';
import styles from './CircleItem.module.scss';

export const Circleitem: FC<{
  item: TribeDetail;
  selectedCircle: string | null;
  setSelectedCircle: (id: string | null) => void;
}> = ({ item, selectedCircle, setSelectedCircle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setSelectedCircle(item._id); // Update the selected circle ID
    navigate(`/circle/${item._id}`);
  };

  const isWideScreen = useMediaQuery('(min-width:550px)');

  const getTruncatedTribeName = (name: string) => {
    if (name.length > 15) {
      return `${name.slice(0, 13)}...`;
    }
    return name;
  };

  const formatRole = (role: string) => {
    if (!role) return '';
    return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
  };

  if (!item.tribe_name) {
    return null;
  }

  const isSelected =
    (location.pathname.startsWith(`/circle/${item._id}`) &&
      (location.pathname === `/circle/${item._id}` ||
        location.pathname.includes(`/circle/${item._id}/`))) ||
    location.pathname === `/edit-circle/${item._id}`;

  return (
    <div onClick={handleClick} className={styles.container}>
      <Avatar
        src={
          item.tribe_avatar_url ? item.tribe_avatar_url : item.leader_avatar_url
        }
        sx={{ width: isWideScreen ? 32 : 35, height: isWideScreen ? 32 : 35 }}
      >
        {item.tribe_name ? item.tribe_name.charAt(0) : 'C'}
      </Avatar>
      <div className={styles.textWrapper}>
        <div
          className={cn(styles.tribeName, { [styles.selected]: isSelected })}
        >
          {getTruncatedTribeName(item.tribe_name)}
        </div>
        <div
          className={cn(styles.leaderName, { [styles.selected]: isSelected })}
        >
          {formatRole(item.role)}
        </div>
      </div>
    </div>
  );
};
