import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { usersApi } from 'src/api/user';
import { UserInfoProps } from '../Home/const';
import styles from './UserPage.module.scss';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { SubscriptionInfo } from '../Home/SubscriptionInfo/SubscriptionInfo';
import { UserInfo } from './UserInfo/UserInfo';

export const UserPage = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState<UserInfoProps | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      usersApi
        .getUserById(id)
        .then((res) => {
          setUserData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch user data:', error);
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <div className={styles.container}>
      {loading ? (
        <MaterialCircularPogress />
      ) : userData ? (
        <>
          <UserInfo {...userData} />
          <SubscriptionInfo {...userData} />
          {/* <TabsAuction /> */}
        </>
      ) : null}
    </div>
  );
};
