import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import screenOne from 'src/assets/images/howToPage/Picture24.png';
import screenTwo from 'src/assets/images/howToPage/Picture25.png';
import screenThree from 'src/assets/images/howToPage/Picture26.png';
import screenFour from 'src/assets/images/howToPage/Picture27.png';
import styles from './CreateChallenge.module.scss';
import {
  IconType,
  WarningBlock,
} from 'src/components/common/HowToPageComponents/WarningBlock/WarningBlock';

export const CreateChallnge = () => {
  return (
    <div>
      <div className={styles.blockTitle}>7- Create a Challenge</div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenOne}
          title="7.1 From your Feed, tap on your avatar to open the user menu."
        />
        <BlockWithImg
          src={screenTwo}
          title="7.2 Tap the Manage button for the Circle you wish to post in."
        />
        <BlockWithImg
          src={screenThree}
          title="7.3 On the Circle dashboard, tap the New Post button."
        />
      </div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenFour}
          title={
            <>
              7.4 Either select or record a video to use for your Challenge.
              <br />
              We recommend recording live to provide your Members with a more
              genuine, authentic experience and set the tone that their
              contributions should follow suit and not be overly polished.
            </>
          }
        />
        <div className={styles.description}>
          7.5 Add one or more Challenges by clicking the Add Challenge button.
          <br />
          <br />
          7.6 Click the Create Challenge button to finish. It may take several
          minutes for the video to process and become visible to you and your
          Members.
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.info}>
          <WarningBlock
            iconType={IconType.Warning}
            title="Video length cannot exceed 60 seconds. Video must be in portrait mode."
          />
        </div>
      </div>
    </div>
  );
};
