import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import classNames from 'classnames';

import styles from './VideoPlayer.module.scss';
import { SelectedVideoModal } from 'src/pages/UserCircle/Tabs/Videos/SelectedVideoModal/SelectedVideoModal';
import { CATEGORIES_LIGHT } from 'src/const/Categories/CategoresLight';
import dunbarWhite from 'src/assets/images/dunbar/dunbar-white.png';

interface VideoPlayerProps {
  videoUrl: string;
  post: any;
  index: number;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, post, index }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [browser, setBrowser] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const vendor = navigator.vendor.toLowerCase();

    if (vendor.includes('google inc.')) {
      setBrowser('Chrome');
    } else if (
      vendor.includes('apple') &&
      userAgent.includes('safari') &&
      !userAgent.includes('chrome')
    ) {
      setBrowser('Safari');
    } else {
      setBrowser('Other');
    }
  }, []);

  const displayedChallenges = post.challenges.slice(0, 2);
  const moreChallengesCount =
    post.challenges.length - displayedChallenges.length;
  // const relativeTime = calculateRelativeTime(post.createdAt);

  const getCategoryImage = (category: string) => {
    const categoryData = CATEGORIES_LIGHT.find(
      (cat: any) => cat.name === category.toLowerCase()
    );
    return categoryData
      ? categoryData.img
      : CATEGORIES_LIGHT.find((cat: any) => cat.name === 'other')?.img;
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(videoElement);
        return () => hls.destroy();
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        videoElement.src = videoUrl;
      }
    }
  }, [videoUrl]);

  useEffect(() => {
    if (isOpenModal && videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [isOpenModal]);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  return (
    <div>
      <div className={styles.videoContainer}>
        <div
          className={classNames(styles.videoWrapper, {
            [styles.videoWrapperGoogle]: browser === 'Chrome',
            [styles.videoWrapperSafari]: browser === 'Safari',
          })}
        >
          <video
            className={styles.video}
            ref={videoRef}
            onClick={handleVideoClick}
            controls={false}
            autoPlay={false}
            loop
            muted={false}
            data-index={index}
            playsInline
          />
          {/* <div className={styles.commentOverlay}>{post.comments.length}</div> */}

          <div className={styles.challengeOverlay}>
            <div className={styles.tribeName}>{post.tribe_name}</div>
            {displayedChallenges.map((challenge: any, index: any) => (
              <div className={styles.challengeItem} key={index}>
                <img
                  src={getCategoryImage(challenge.category)}
                  alt={challenge.category}
                  className={styles.challengeImage}
                />
                {truncateText(challenge.name, 40)}
              </div>
            ))}
            {moreChallengesCount > 0 && (
              <div className={styles.moreItem}>+{moreChallengesCount} more</div>
            )}
            <div
              className={styles.dunbarIconWrapper}
              onClick={() => setIsOpenModal(true)}
            >
              <img
                src={dunbarWhite}
                alt="Dunbar"
                className={styles.dunbarIcon}
              />
            </div>
          </div>
        </div>
      </div>

      <SelectedVideoModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal} // Close modal callback
        videoId={post._id}
        videoUrl={post.video_url}
      />
    </div>
  );
};

export default VideoPlayer;
