import { FC, useState, ChangeEvent, useEffect } from 'react';
import styles from './AddHabitModal.module.scss';
import { MuiModal } from 'src/components/material/MuiModal';
import { CommonFiled } from 'src/pages/CreateProfile/CommonField/CommonField';
import { INFO } from './const';
import { ChooseCategory } from './ChooseCategory/ChooseCategory';

interface Habit {
  id: string;
  name: string;
  category: string;
}

interface AddHabitModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  onSave: (habit: Habit, isEditing: boolean) => void;
  onDelete: (habit: Habit) => void;
  initialHabit?: Habit | null;
}

export const AddHabitModal: FC<AddHabitModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  onSave,
  onDelete,
  initialHabit = null,
}) => {
  const [habitName, setHabitName] = useState(initialHabit?.name || '');
  const [habitCategory, setHabitCategory] = useState(
    initialHabit?.category || ''
  );

  useEffect(() => {
    if (initialHabit) {
      setHabitName(initialHabit.name);
      setHabitCategory(initialHabit.category);
    } else {
      setHabitName('');
      setHabitCategory('');
    }
  }, [initialHabit, isOpenModal]);

  const handleSave = () => {
    if (habitName.trim() && habitCategory.trim()) {
      const isEditing = !!initialHabit;
      onSave(
        {
          id: initialHabit?.id || Date.now().toString(),
          name: habitName,
          category: habitCategory,
        },
        isEditing
      );
      setIsOpenModal(false);
    }
  };

  const handleDelete = () => {
    if (initialHabit) {
      onDelete(initialHabit);
      setIsOpenModal(false);
    }
  };

  const handleHabitNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setHabitName(event.target.value);
  };

  const handleCategoryChange = (category: string) => {
    setHabitCategory(category);
  };

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div className={styles.title}>
        {initialHabit ? 'Edit Habit' : 'Add Habit'}
      </div>
      <CommonFiled
        title={INFO.title}
        description={INFO.description}
        placeholder={INFO.placeholder}
        charactersLength={INFO.charactersLength}
        isIcon={false}
        value={habitName}
        onChange={handleHabitNameChange}
      />
      <div>
        <ChooseCategory
          selectedCategory={habitCategory}
          onCategoryChange={handleCategoryChange}
        />
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.btnGray}
          onClick={initialHabit ? handleDelete : () => setIsOpenModal(false)}
        >
          {initialHabit ? <div>Delete</div> : <div>Cancel</div>}
        </button>
        <button className={styles.btn} onClick={handleSave}>
          Save
        </button>
      </div>
    </MuiModal>
  );
};
