import { FC, useCallback, useEffect, useState } from 'react';
import { MuiModal } from 'src/components/material/MuiModal';
import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import styles from './AddMembersModal.module.scss';
import { AddMember } from './AddMember/AddMember';
// import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Popover from '@mui/material/Popover';
import { circleApi } from 'src/api/circles';

interface AddMembersModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  circleId: string;
  tribeName: string;
  isLeader: boolean;
}

interface Member {
  avatar_url: string;
  bio: string;
  full_name: string;
  id: string;
  role: string;
  tribes: Array<string>;
}

export const AddMembersModal: FC<AddMembersModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  circleId,
  tribeName,
  isLeader,
}) => {
  const [showAddMember, setShowAddMember] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);

  const fetchMembers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await circleApi.getMembersOfCircle(circleId);
      setMembers(response.data.result);
    } catch (error) {
      console.error('Failed to fetch members:', error);
    } finally {
      setLoading(false);
    }
  }, [circleId, setMembers, setLoading]);

  useEffect(() => {
    if (isOpenModal) {
      fetchMembers();
    }
  }, [isOpenModal, circleId, fetchMembers]);

  // const handleClick = (event: any, memberId: string) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  //   setSelectedMemberId(memberId);
  // };

  const handleAddMemberClick = () => {
    setShowAddMember(true);
  };

  const handleRemoveMember = async () => {
    if (selectedMemberId) {
      try {
        await circleApi.deleteMember(circleId, selectedMemberId);
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== selectedMemberId)
        );
        setAnchorEl(null);
        setSelectedMemberId(null);
      } catch (error) {
        console.error('Failed to remove member:', error);
      }
    }
  };

  // const handleMemberClick = (memberId: string) => {
  //   navigate(`/user/${memberId}`);
  // };

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div>
        {showAddMember ? (
          <AddMember
            setShowAddMember={setShowAddMember}
            circleId={circleId}
            fetchMembers={fetchMembers}
          />
        ) : (
          <div>
            <div className={styles.title}>Members of {tribeName}</div>
            <div className={styles.userList}>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                members.map((member) => (
                  <div
                    className={styles.wrapper}
                    key={member.id}
                    // onClick={() => handleMemberClick(member.id)} // Navigate on click
                  >
                    <div className={styles.user}>
                      <Avatar
                        src={member.avatar_url}
                        sx={{ width: 45, height: 45 }}
                      >
                        N
                      </Avatar>
                      <div>
                        <div className={styles.userTitle}>
                          {member.full_name}
                        </div>
                      </div>
                    </div>
                    <div className={styles.iconsWrapper}>
                      {/* <div onClick={(event) => handleClick(event, member.id)}>
                        <MoreVertRoundedIcon sx={{ color: 'black' }} />
                      </div> */}
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <div
                          className={styles.popover}
                          onClick={handleRemoveMember}
                        >
                          Remove member
                        </div>
                      </Popover>
                    </div>
                  </div>
                ))
              )}
            </div>
            {isLeader && (
              <button onClick={handleAddMemberClick} className={styles.btn}>
                Add Member
              </button>
            )}
          </div>
        )}
      </div>
    </MuiModal>
  );
};
