import React, { useState } from 'react';
import MaterialTextField from 'src/components/material/textField';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import styles from './AddMember.module.scss';
import { circleApi } from 'src/api/circles';

interface AddMemberProps {
  setShowAddMember: (show: boolean) => void;
  circleId: string;
  fetchMembers: () => void; // Add fetchMembers as a prop
}

export const AddMember: React.FC<AddMemberProps> = ({
  setShowAddMember,
  circleId,
  fetchMembers,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [role, setRole] = useState<string>('MEMBER');

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const addMember = async () => {
    const updatedPhone = phoneNumber.replace(/\s+/g, '');
    try {
      await circleApi.inviteMember(circleId, role, updatedPhone);
      fetchMembers();
      setShowAddMember(false);
    } catch (error: any) {
      console.error('Failed to add member:', error);
      setError(error.response.data.message);
    }
  };

  return (
    <div className={styles.container}>
      <div onClick={() => setShowAddMember(false)}>
        <KeyboardBackspaceIcon sx={{ color: 'black', cursor: 'pointer' }} />
      </div>
      <FormControl component="fieldset" style={{ marginTop: '16px' }}>
        <RadioGroup
          value={role}
          onChange={handleRoleChange}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControlLabel
            value="MEMBER"
            control={<Radio />}
            label="Member"
            className={styles.description}
          />
          <FormControlLabel
            value="COLEADER"
            control={<Radio />}
            label="Co-leader"
            className={styles.description}
          />
        </RadioGroup>
      </FormControl>
      <MaterialTextField
        isPhoneNumber
        label="Phone Number"
        variant="outlined"
        name="phoneNumber"
        id="phone-number"
        error={!!error}
        helperText={error}
        value={phoneNumber}
        onChange={(e: any) => setPhoneNumber(e.target.value)}
        color="primary"
      />
      <button onClick={addMember} className={styles.btn}>
        Add Member
      </button>
    </div>
  );
};
